import React, {Component} from "react";
import Content from "./components/Content";
import TopBarFixed from "./components/TopBarFixed";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: 0,
            windowHeight: 0,
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({
            windowWidth: typeof window !== "undefined" ? window.innerWidth : 0,
            windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
        })
        // let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        // let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    }

    render() {
        const {windowWidth} = this.state;

        const styles = {
            white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            bosaBlue: (opacity = 1) => `rgba(38, 88, 107, ${opacity})`,
            // topBarHeight: 40,
            topBarFixedHeight: windowWidth > 768 ? 50 : 50,
            footerMenuHeight: 50,
            showFooterMenuText: windowWidth > 500,
            showSidebar: windowWidth > 768,
            sidebarWidth: windowWidth < 1100 ? 50 : 150,
            sidebarCollapsed: windowWidth < 1100,
            contactUsDivDisplay: windowWidth > 768 ? 'flex' : 'block',
            mainDivJustifyContent: windowWidth > 768 ? 'space-between' : 'center',
            mainBimcoMargin: windowWidth > 768 ? '0' : 'auto',
        };

        return (
            <div
                style={{
                    backgroundColor: styles.black(0.09),
                    minHeight: "100vh",
                    position: "relative"
                }}
            >
                {
                    // styles.showSidebar ? (
                    //     <Sidebar menuItems={menuItems} styles={styles} />
                    // ) : (
                    //     <TopBar styles={styles} />
                    // )
                }
                <TopBarFixed styles={styles}/>

                <Content styles={styles}/>

                {/*{!styles.showSidebar && (*/}
                {/*    <FooterMenu menuItems={menuItems} styles={styles} />*/}
                {/*)}*/}
            </div>
        );
    }
}

export default App;
