import React, {useEffect, useState} from "react";
import {useConnect} from "remx";
import {pageStore} from "../utils/pageStore";
import Table from 'react-bootstrap/Table'
import Carousel from 'react-bootstrap/Carousel'
import imageBimco from '../assets/other/bimcoLogo.png'
import BosaTnCPdf from '../assets/bosaweb/terms/Bosa Chartering terms of conduct 2020.pdf'
import {Tab, Tabs} from "react-bootstrap";

const Content = ({styles}) => {
    const {showSidebar} = styles;
    const [shipIndex, setShipIndex] = useState(0);
    const [usIndex, setUsIndex] = useState(0);
    const handleUsSelect = (selectedIndex, e) => {
        setUsIndex(selectedIndex);
    };
    const handleShipSelect = (selectedIndex, e) => {
        setShipIndex(selectedIndex);
    };

    function importAll(r) {
        return r.keys().map(r);
    }

    const imagesUs = importAll(require.context('../assets/bosaweb/Us', false, /\.(png|jpe?g|svg||JPG)$/));
    const imagesShips = importAll(require.context('../assets/bosaweb/Ships', false, /\.(png|jpe?g|svg||JPG)$/));
    // const imageBimco = importAll(require.context('../assets/other/', false, /\.(png|jpe?g|svg)$/));
    useEffect(() => {
    })

    const pageSelected = useConnect(pageStore.getPageSelected);
    const contactUsDivStyle = {
        display: styles.contactUsDivDisplay,
        justifyContent: styles.mainDivJustifyContent,//"space-between",
        alignItems: styles.mainDivJustifyContent,
    }
    const mainBimcoMargin = {
        width: '30vh',
        justifyContent: 'center',
        alignItems: 'center',
        margin: styles.mainBimcoMargin
    }

    const posts = {
        1: {
            mainTitle: 'BOSA Chartering Co. Ltd. Istanbul',
            title: 'Our Story',
            content: (<div>
                <br/><br/>
                <p><b><em>Bosa Chartering Co. Ltd.</em></b> is a dry cargo ship broking company based in Istanbul,
                    Turkey. The Company was established in 1994 by well experienced brokers in the shipping sector
                    already
                    at that time.<br/>
                    Our brokers come from shipping background and families and we have chosen to keep company size
                    stable to
                    ensure a close, reliable and a fully dedicated service to our clients at the <b><em>highest
                        international shipbroking standard</em></b> achievable.

                    Our brokers, all being partners in The Company, have no competition among themselves, so they are
                    able
                    to substitute one another gladly, which is a real asset for <b><em>customer satisfaction with 24/7
                        service</em></b> and forms a staunch base to maintain our customer portfolio in the long
                    term.<br/><br/>

                    Hence, BOSA have been working closely with giant steel factories, reputable ship-owners and
                    operators
                    and many other charterers/traders and co-brokers worldwide for many years with success and
                    apparently,
                    with a well deserved reputation.<br/><br/>

                    The core staff, who have been here from the beginning, had, after completing their educational
                    degrees;
                    (i.e. M.Sc. in shipping and maritime studies, B.Sc. and B.A.'s respectively) have been working
                    prior to
                    Bosa as follows: Murat Senova started with a ship-owner (1984) and carried on with a leading
                    shipbroker / operator from 1989 until establishment of Bosa in 1994. Adnan Turkes had worked for a
                    major Turkish ship-owner for 14 years until 1994 and Cemal Senova had started with a shipbroker / tc
                    operator (2
                    years)
                    prior establishment of Bosa. Ahmet Senova started ship broking career with BOSA in 1996 and our
                    youngest
                    broker Onur Turkes is with us since 2010. Our services team; Mr. Gokhan Sefer is the post-fixture
                    member
                    of the team since 2011. Mrs. Kadriye (Celik) Eren is our accounts manager since 1996 and Adil Eren
                    is
                    with us since 2004.<br/><br/>

                    Whether you are a Ship-owner, Charterer or a Trader, BOSA staff will be more than glad to know your
                    inquiries to render the best possible service in day to day chartering as well as sharing its market
                    opinion/experience and evaluating your passionate projects, making feasibility calculations
                    pertaining
                    to seaborne transport worldwide. We shall only be pleased to receive your calls.
                </p>
                <div className="footer">
                    <a className='footerLink' href={BosaTnCPdf} target={'_blank'}>Terms & Conditions</a>
                </div>
            </div>)
        },
        2: {
            title: 'Contact Us',
            content: (<div>
                <br/><br/>
                <div style={contactUsDivStyle}>
        <span style={{
            flex: 1,
            display: 'grid',
            alignItems: 'center',
        }}>
        <Table style={{
            verticalAlign: 'middle'
        }} hover size={'sm'}>
            <tbody>
        <tr>
          <td><b>Main Switchboard</b></td>
          <td><u><a href="tel:+902164673700">+90 216 467 37 00</a></u></td>
        </tr>
        <tr>
          <td><b>Fax</b></td>
          <td><u><a href="tel:+902164673711">+90 216 467 37 11</a></u></td>
        </tr>
        <tr>
          <td><b>General E-Mail</b></td>
          <td><u><a href={'mailto:${bosa@bosaship.com}'}>bosa@bosaship.com</a></u></td>
        </tr>
        <tr>
          <td><b>Post-Fixture</b></td>
          <td><u><a href={'mailto:${ops@bosaship.com}'}>ops@bosaship.com</a></u></td>
        </tr>
        </tbody>
      </Table>
            <h3>Location</h3>
          <p>We are located on the Asian side of Istanbul, next to The Sea of Marmara.<br/>
              It is mainly a residential area, on the famous shopping street; Bagdat Caddesi, and a popular street where many Pubs and Restaurants are located; Caddebostan Barlar Sokağı.</p>
          </span>
                    <span style={{flex: 0.1}}/>
                    <span style={{
                        flex: 1,
                        display: 'grid',
                        alignItems: 'center',
                    }}>
          {/*  <h3>Location</h3>*/}
                        {/*<p>We are located on the Asian side of Istanbul, next to The Sea of Marmara.<br/>*/}
                        {/*    It is mainly a residential area, on the famous shopping street; Bagdat Caddesi, and a popular street where many Pubs and Restaurants are located; Caddebostan Barlar Sokağı.</p>*/}
                        <h3>Visiting Address</h3>
          <p>Göztepe Mah.<br/>
            Tanzimat sok. No:64<br/>Akson-Bosa Köşkü <br/>Kadıköy / Istanbul<br/>
            34728 Turkey</p>
            <h3>Directions in Turkish</h3>
          <p>Göztepe itfaiye'nin yanı, Ihsan Kursunoglu Lisesi karşısı, Kadıköy.</p>
        </span>
                </div>
                <iframe
                    title={'Bosa Chartering'}
                    width="100%"
                    // height="100%"
                    // frameBorder="0" style="border:0"
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDhiwRuSnfbax1CmgIMnUtR_hfYsZDB5Jw&q=Bosa+Gemi+Kiralama"
                >
                </iframe>
            </div>)
        },
        3: {
            title: 'Gallery',
            content: (
                <div style={{zIndex: -1}}>
                    <br/><br/>
                    <Tabs defaultActiveKey="ships" id="uncontrolled-gallery-tabs">
                        <Tab eventKey="ships" title="Ships">
                            <Carousel style={{
                                // width:'100%'
                            }} activeIndex={shipIndex} onSelect={handleShipSelect}>
                                {
                                    // images.map(({id, src, title, description}) => (
                                    imagesShips.map((image, idx) => (
                                        <Carousel.Item>
                                            <img class="d-block img-fluid" key={idx} src={image['default']}/>
                                            {/*<Carousel.Caption>*/}
                                            {/*<h3>{description}</h3>*/}
                                            {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                            {/*</Carousel.Caption>*/}
                                        </Carousel.Item>
                                    ))
                                }
                            </Carousel>
                        </Tab>
                        <Tab eventKey="us" title="Us">
                            <Carousel style={{
                                // width:'100%'
                            }} activeIndex={usIndex} onSelect={handleUsSelect}>
                                {
                                    // images.map(({id, src, title, description}) => (
                                    imagesUs.map((image, idx) => (
                                        <Carousel.Item>
                                            <img className="d-block img-fluid" key={idx} src={image['default']}/>
                                            {/*<Carousel.Caption>*/}
                                            {/*<h3>{description}</h3>*/}
                                            {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                            {/*</Carousel.Caption>*/}
                                        </Carousel.Item>
                                    ))
                                }
                            </Carousel>
                        </Tab>
                    </Tabs>
                </div>)
        },
    }

    // const posts = Array(20).fill(dummyPost);

    const contentStyle = {
        paddingTop: styles.topBarFixedHeight + 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: showSidebar ? 20 : styles.footerMenuHeight + 20,
        // paddingLeft: showSidebar ? styles.sidebarWidth + 20 : 20
    };

    return (
        <div style={contentStyle}>
            {/*<div>asd</div>*/}
            {/*{posts.map((post, i) => {*/}
            {/*  return (*/}
            <div style={{}}>
                {posts[pageSelected].mainTitle &&
                    <div
                        style={contactUsDivStyle}
                    >
                        <h1 style={{
                            textAlign: 'center',
                            fontSize: '3.5em',
                        }}>{posts[pageSelected].mainTitle}</h1>
                        <div style={mainBimcoMargin}>
                            <img style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                // verticalAlign: 'middle',
                                // alignSelf: 'center',
                                height: '10vh',
                                width: 'auto'
                            }} src={imageBimco}/>
                            <p style={{textAlign:'center'}}>Bosa is a proud member of BIMCO since 1994</p>
                        </div>
                    </div>
                }

                <h2 style={{marginBottom: 0}}>{posts[pageSelected].title}</h2>
                {/*<div key={i+20} style={{display:'flex' }}>*/}
                {posts[pageSelected].content}
                {/*</div>*/}
            </div>
            {/*); })}*/}
        </div>
    );
};

export default Content;
