import remx from "remx";

const initialState = {
    pageSelected: 1
};
const state = remx.state(initialState);
const getters = remx.getters({
    getPageSelected() {
        return state.pageSelected;
    },
});

const setters = remx.setters({
    setPageSelected(pageSelected) {
        state.pageSelected = pageSelected
    },
});
export const pageStore = {
    ...getters,
    ...setters
};
