import React from "react";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import {pageStore} from "../utils/pageStore";
import {useConnect} from 'remx';

const TopBarFixed = ({styles}) => {
    // const [radioValue, setRadioValue] = useState('1');
    const pageSelected = useConnect(pageStore.getPageSelected);
    const radios = [
        {name: 'Home', value: 1},
        {name: 'Contact', value: 2},
        {name: 'Gallery', value: 3}];

    const handleChange = (val) => {
        // console.log(pageStore.getPageSelected())
        // console.log(val)
        pageStore.setPageSelected(val)
    };
    const topBarFixedStyle = {
        zIndex: 1,
        position: "fixed",
        top: 0,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        // height: styles.topBarFixedHeight,
        backgroundColor: "#fff",
        borderBottom: "1px solid #d8d8d8",
        fontWeight: "bold",
        // padding: "0px 20px",
        // boxSizing: "border-box"
    };
    const topBarButtonStyle = {
        flex: 1,
        textAlign: "center",
        // verticalAlign: 'middle',
        // alignSelf: 'center',
        // justifyContent: "center",
        // alignItems: "center",
        // height: '15%',
    }

    return (
        <div style={topBarFixedStyle}>
            {/*<Button variant="outline-dark">Home</Button>*/}
            <style type="text/css">
                {`
                .btn-bosa {
                  background-color: #1C586B;
                  color: white;
                }
                .btn-bosa:hover{
                  background-color: #0C485B;
                }
                .btn-bosa:not(:disabled):not(.disabled).active{
                  background-color: #033F52;
                }
                `}
            </style>
            <ToggleButtonGroup
                style={{
                    flex: 1,
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    height: '100%',
                }} type="radio" name={'radio'} value={pageSelected} onChange={handleChange}>
                {radios.map((radio, index) => (
                    <ToggleButton
                        key={index}
                        className={'btn-bosa'}
                        variant="outline-dark"
                        style={topBarButtonStyle}
                        value={radio.value}
                        name={radio.name}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};
export default TopBarFixed;
